const defaultFavicon = process.env.NODE_ENV === 'development' 
  ? '/favicon.ico' 
  : 'https://seniorshopping.com/favicon-32x32.png',
  defaultTitle = 'Senior Shopping Service, Inc.'

const rotatingFavicon = process.env.NODE_ENV === 'development' 
  ? '/favicon-exclamation.png' 
  : 'https://seniorshopping.com/favicon-exclamation.png',
  rotatingTitles = [
    `Don't forget this!`,
    `Psst...`
  ]

function changeFavicon(newSrc: string) {
  let link: HTMLLinkElement | null = document.querySelector("link[rel='icon']");
  
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = newSrc;
}

export const handleTitleFaviconChange = () => {
  const currentTitle = document.title,
    isVisible = document.visibilityState === 'visible'

  if (isVisible) {
    changeFavicon(defaultFavicon)
    document.title = defaultTitle

  } else {
    changeFavicon(rotatingFavicon)
    document.title = currentTitle === rotatingTitles[1] ? rotatingTitles[0] : rotatingTitles[1]
  
    setTimeout(() => handleTitleFaviconChange(), 1000)
  }
}