import handleReCAPTCHA from '../../../utility/google/handleReCAPTCHA';
import {authService} from '../../../../utility';
import {useStore} from '../../../../store';
import {forgotUsernameAPI, forgotPasswordAPI, resetPasswordAPI, validateTokenAPI} from '../../../../api';
import React, {useState, useEffect, useRef} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {TextField} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

export type HelpCenterParams = {
  type: 'username' | 'password' | 'passwordReset';
  token?: string;
};

const HelpCenter = () => {
  const {type, token} = useParams<HelpCenterParams>(),
    recaptchaRef = useRef<ReCAPTCHA>(null),
    history = useHistory(),
    dispatchSetAlert = useStore((state) => state.dispatchSetAlert),
    [wasSuccessful, setWasSuccessful] = useState(false),
    [username, setUsername] = useState(''),
    [email, setEmail] = useState(''),
    [newPassword, setNewPassword] = useState(''),
    [newPasswordConfirm, setNewPasswordConfirm] = useState(''),
    
  validateToken = async () => {
    const onInvalid = () => {
      dispatchSetAlert({
        status: 'danger',
        header: 'Invalid Token',
        text: 'Token is invalid'
      });
      history.push('/');
    };

    if (!token) {
      onInvalid();
      return;
    }
    
    const res = await validateTokenAPI(token);
    if (res.status !== 200) {
      onInvalid();
    }
  },
  
  submitForgotUsername = async () => {
    if (!email || email === '') return;

    const captchaToken = await handleReCAPTCHA(recaptchaRef);
    if (!captchaToken) return;

    const res = await forgotUsernameAPI(email, captchaToken);
    setWasSuccessful(res.status === 200);
  },
  
  submitForgotEmail = async () => {
    if (!username || !email) return;

    const captchaToken = await handleReCAPTCHA(recaptchaRef);
    if (!captchaToken) return;

    const res = await forgotPasswordAPI(email, username, captchaToken);
    setWasSuccessful(res.status === 200);
  },
  
  resetPassword = async () => {
    if (!token) {
      dispatchSetAlert({
        status: 'danger',
        header: 'Error',
        text: 'There was an unexpected error, please try again later'
      });
      return;
    }

    if (newPassword !== newPasswordConfirm) {
      dispatchSetAlert({
        status: 'warning',
        header: 'Passwords do not match',
        text: 'Passwords do not match'
      });
      return;
    }

    const captchaToken = await handleReCAPTCHA(recaptchaRef);
    if (!captchaToken) return;

    const res = await resetPasswordAPI(
      token,
      authService.password.hash(newPassword),
      authService.password.hash(newPasswordConfirm),
      captchaToken
    );
    setWasSuccessful(res.status === 200);
  };

  useEffect(() => {
    if (type === 'passwordReset') {
      console.log('TOKEN: ', token)
      validateToken();
    }
  }, [token]);

  return (
    <section className='help-center'>
      {
        type === 'username' &&
        <div className='help-center__main help-center__main--username'>
          <h1 className='help-center__heading'>
            {
              wasSuccessful ?
              `Success` :
              `Forgot Username?`
            }
          </h1>
          <p className='help-center__desc'>
            {
              wasSuccessful ?
              `An email was sent. Please check your inbox to recover your username.` :
              'In order to recover your username, we need the email that is tied to your account.'
            }
          </p>
          {
            !wasSuccessful &&
            <TextField
              label='Email Address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          }
          {
            wasSuccessful ?
            <button
              className='btn btn--primary'
              onClick={() => history.push('/login')}
            >
              Back to Login
            </button> :
            <button
              className='btn btn--primary'
              onClick={submitForgotUsername}
            >
              Send Email
            </button>
          }
        </div>
      }
      {
        type === 'password' &&
        <div className='help-center__main help-center__main--password'>
          <h1 className='help-center__heading'>
            {
              wasSuccessful ?
              `Success` :
              `Forgot Password?`
            }
          </h1>
          <p className='help-center__desc'>
            {
              wasSuccessful ?
              `An email was sent. Please check your inbox to recover your password` :
              `In order to recover your password, we need the email and username that is tied to your account.`
            }
          </p>
          {
            !wasSuccessful &&
            <div className='help-center__input-group'>
              <TextField
                label='Username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <TextField
                label='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          }
          {
            wasSuccessful ?
            <button
              className='btn btn--primary'
              onClick={() => history.push('/login')}
            >
              Back to Login
            </button> :
            <button
              className='btn btn--primary'
              onClick={submitForgotEmail}
            >
              Send Email
            </button>
          }
        </div>
      }
      {
        type === 'passwordReset' &&
        token &&
        <div className='help-center__main help-center__main--password-reset'>
          <h1 className='help-center__heading'>
            {
              wasSuccessful ?
              `Success` :
              `Reset Password`
            }
          </h1>
          {
            wasSuccessful &&
            <>
              <p className='help-center__desc'>
                Your password has been reset.
              </p>
              <button
                className='btn btn--primary'
                onClick={() => history.push('/login')}
              >
                Back to Login
              </button>
            </>
          }
          {
            !wasSuccessful &&
            <>
              <span className='help-center__input-group'>
                <TextField
                  type='password'
                  label='New Password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                  type='password'
                  label='New Password Confirm'
                  value={newPasswordConfirm}
                  onChange={(e) => setNewPasswordConfirm(e.target.value)}
                />
              </span>
              <button className='btn btn--primary' onClick={resetPassword}>
                Reset Password
              </button>
            </>
          }
        </div>
      }
      <ReCAPTCHA
        className='recaptcha'
        ref={recaptchaRef}
        size='invisible'
        sitekey={authService.recaptcha.siteKey}
      />
    </section>
  );
};

export default HelpCenter;
