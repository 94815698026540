import React, {SetStateAction, Dispatch} from 'react'
import Slider from 'react-slick';
import SwiperCore, {Autoplay, Pagination, Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react.js';
import {Link} from 'react-router-dom';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import Image from './Image';

SwiperCore.use([Autoplay, Pagination, Navigation]);

export type CarouselItem = {
  name?: string;
  image: string;
  link?: string;
};

type Props = {
  imageLocation?: string;
  carouselItems: Array<CarouselItem>,
  currentIndex?: number;
  setCurrentIndex?: Dispatch<SetStateAction<number>>;
};

const Carousel = ({imageLocation, carouselItems, currentIndex, setCurrentIndex}: Props) => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  const onSlideChange = (e: SwiperCore) => {
    if (setCurrentIndex  === undefined) return;
    
    setCurrentIndex(e.activeIndex);
  };

  return (
    // <div>
    //   {props.carouselItems.map((item) => (
    //     <div>
    //       <Link to={`${item.path}/${item.param}`}>
    //         <img src={item.image} alt={item.name} />
    //       </Link>
    //     </div>
    //   ))}
    // </div>
    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      autoplay={{
      'delay': 6000,
      'disableOnInteraction': true
      }}
      pagination={{
      'clickable': true
      }}
      navigation={true}
      className='swiper'
      onSlideChange={onSlideChange}
    >
      {
        carouselItems.map((item, index) =>
          <SwiperSlide key={index} className='swiper__slide'>
            {
              item.link ?
              <Link to={item.link}>
                <Image src={imageLocation + item.image} alt={item.name || 'carousel item'}/>
              </Link> :
              <Image src={imageLocation + item.image} alt={item.name || 'carousel item'}/>
            }
          </SwiperSlide> 
        )
      }
    </Swiper>
  );
};

export default Carousel;
